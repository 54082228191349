import React from 'react';
import { FormsLoadClientStepOption } from '../../services/api/types/forms.responses';
import { motion } from 'framer-motion';
import APIService from '../../services/api/index.api';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionRemoveAnimatingComponents,
  actionUpdateAnimatingComponents,
} from '../../store/actions/ui.actions';
import { getAnimatingComponents } from '../../store/selectors';
import styles from './index.module.scss';

interface SelectLargeProps {
  inputId: number;
  className?: string;
  style?: React.CSSProperties;
  options: FormsLoadClientStepOption[];
  initialDelay?: number;
  activeOptionIds?: number[] | null;
  onSelect?: (option: FormsLoadClientStepOption) => void;
  multiple?: boolean;
  stepId: number;
}

function SelectLarge({
  options,
  initialDelay = 0,
  activeOptionIds = null,
  onSelect,
  multiple = false,
  inputId,
  stepId,
}: SelectLargeProps) {
  const rendered = React.useRef<Record<number, boolean>>({});
  const componentsAnimating = useSelector(getAnimatingComponents);
  const dispatch = useDispatch();
  React.useEffect(() => {
    rendered.current = {};
  }, [stepId]);
  return (
    <>
      {options?.map((option, index) => {
        const optionActive = activeOptionIds?.includes(option.id);
        return (
          <motion.div
            key={`${inputId}_${option.id}_step-input-option`}
            className={`${styles.container} ${
              optionActive ? styles.containerActive : ''
            }`}
            initial={{
              opacity: 0,
              translateY: '15%',
            }}
            onLoad={() => {
              rendered.current[option.id] = true;
              dispatch(
                actionUpdateAnimatingComponents({
                  components: ['options'],
                }),
              );
            }}
            onAnimationComplete={() => {
              rendered.current[option.id] = true;
              if (componentsAnimating.includes('options')) {
                dispatch(
                  actionRemoveAnimatingComponents({
                    components: ['options'],
                  }),
                );
              }
            }}
            animate={{
              opacity: 1,
              translateY: '0%',
              transition: {
                duration: 0.7,
                delay: rendered.current[option.id]
                  ? 0
                  : initialDelay + 0.35 + index * 0.05,
                ease: 'anticipate',
              },
            }}
            style={{
              order: option.order,
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.4,
                delay: 0,
                ease: 'anticipate',
              },
              pointerEvents: 'none',
            }}
            onClick={() => {
              if (!componentsAnimating.length) {
                if (onSelect) {
                  onSelect(option);
                }
              }
            }}
          >
            <div
              className={`${styles.holder} ${
                optionActive ? styles.holderActive : ''
              }`}
            >
              {option.imageId ? (
                <div className={styles.imageHolder}>
                  <img
                    className={styles.image}
                    src={APIService.imagesService.createImageUrl(
                      option.imageId,
                    )}
                    onLoad={(e: any) =>
                      e.target.classList.add(styles.imageLoaded)
                    }
                  />
                  <div className={styles.imageBackground} />
                </div>
              ) : null}
              <span className={styles.label}>{option.value}</span>
            </div>
          </motion.div>
        );
      })}
    </>
  );
}

export default SelectLarge;
