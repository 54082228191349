import { FormsLoadClientStepOption } from '../../services/api/types/forms.responses';
import {
  ActionLoadFormPayload,
  ActionNextStepPayload,
  ActionRecordAnswerPayload,
  ActionRecordLeadPayload,
  ActionSetDemoSubmissionPayload,
} from '../actions/types/form.actions';
import { ReduxActionPayload } from '../actions/types/index.d';
import { ActionTypes } from '../actions/types/type.actions.d';
import { FormState, StepAnswers } from './types/form.types.d';

const defaultState: FormState = {
  loaded: false,
  logoImageId: null,
  brandColor: null,
  widgetTitle: null,
  widgetButtonText: null,
  phoneNumber: null,
  previousSteps: [],
  steps: null,
  currentStep: null,
  stepAnswers: null,
  clientKey: null,
  lastStepId: null,
  settings: null,
  translations: null,
  isOnFirstStep: true,
  consent: false,
  demoSubmission: false,
  loadClientConfiguration: null,
};

function formReducer(
  state = defaultState,
  action: ReduxActionPayload,
): FormState {
  switch (action.type) {
    case ActionTypes.SET_DEMO_SUBMISSION: {
      const demoSubmissionPayload =
        action.payload as ActionSetDemoSubmissionPayload;
      return { ...state, demoSubmission: demoSubmissionPayload.demoSubmission };
    }

    case ActionTypes.LOAD_FORM: {
      const actionPayload = action.payload as ActionLoadFormPayload;
      const stepAnswers: Record<number, StepAnswers> = {};
      actionPayload.data.steps?.forEach((step) => {
        const sa: StepAnswers = {
          answered: false,
          answers: {},
        };
        if (!step.isFinalStep) {
          step.inputs?.forEach((input) => {
            sa.answers[input.id] = {
              optionId: null,
              value: null,
            };
          });
        } else {
          sa.lead = {
            firstName: null,
            lastName: null,
            email: null,
            address: null,
            phoneNumber: null,
            city: null,
            zip: null,
            additionalNotes: null,
            businessName: null,
          };
        }
        stepAnswers[step.id] = sa;
      });
      return {
        ...state,
        loaded: true,
        logoImageId: actionPayload.data.logoImageId,
        brandColor: actionPayload.data.brandColor,
        widgetButtonText: actionPayload.data.widgetButtonText,
        widgetTitle: actionPayload.data.widgetTitle,
        phoneNumber: actionPayload.data.phoneNumber,
        steps: actionPayload.data.steps,
        stepAnswers,
        currentStep: actionPayload.data.steps?.[0]?.id || null,
        clientKey: actionPayload.data.clientKey,
        lastStepId: actionPayload.data.steps?.[0]?.id || null,
        settings: actionPayload.data.settings,
        translations: actionPayload.data.translations,
        previousSteps: [],
        isOnFirstStep: true,
        loadClientConfiguration: actionPayload.data,
      };
    }

    case ActionTypes.GIVE_CONSENT: {
      return { ...state, consent: true };
    }

    case ActionTypes.REMOVE_CONSENT: {
      return { ...state, consent: false };
    }

    case ActionTypes.FORM_NEXT_STEP: {
      const nextStepPayload = action.payload as ActionNextStepPayload;
      // Find next step
      const currentStepFound = state.steps?.find(
        (step) => step.id === nextStepPayload.current.stepId,
      );
      if (currentStepFound) {
        let currentOptionFound: FormsLoadClientStepOption =
          null as any as FormsLoadClientStepOption;
        state.steps?.find((step) => {
          if (step.id === currentStepFound.id) {
            return step.inputs?.find((inp) => {
              if (
                inp.options?.find((opt) => {
                  if (opt.id === nextStepPayload.current.optionId?.[0]) {
                    currentOptionFound = opt;
                    return true;
                  }
                  return false;
                })
              ) {
                return true;
              }
              return false;
            });
          }
          return false;
        });
        if (currentOptionFound?.nextStepId) {
          const nextStepFound = state.steps?.find(
            (step) => step.id === currentOptionFound.nextStepId,
          );
          if (nextStepFound) {
            return {
              ...state,
              currentStep: nextStepFound.id,
              lastStepId: nextStepFound.id,
              previousSteps: [...state.previousSteps, currentStepFound.id],
              isOnFirstStep: false,
            };
          }
        } else if (currentStepFound.nextStepId) {
          const nextStepFound = state.steps?.find(
            (step) => step.id === currentStepFound.nextStepId,
          );
          if (nextStepFound) {
            return {
              ...state,
              currentStep: nextStepFound.id,
              lastStepId: nextStepFound.id,
              previousSteps: [...state.previousSteps, currentStepFound.id],
              isOnFirstStep: false,
            };
          }
        }
      }
      return state;
    }

    case ActionTypes.FORM_PREVIOUS_STEP: {
      if (state.previousSteps.length) {
        const previousStepId =
          state.previousSteps[state.previousSteps.length - 1];
        const newPreviousSteps = [...state.previousSteps.slice(0, -1)];
        return {
          ...state,
          currentStep: previousStepId,
          previousSteps: newPreviousSteps,
          isOnFirstStep: !newPreviousSteps.length,
        };
      }
      return {
        ...state,
        isOnFirstStep: true,
      };
    }

    case ActionTypes.RECORD_ANSWER: {
      const actionPayload: ActionRecordAnswerPayload = action.payload;
      if (
        state.stepAnswers?.[actionPayload.stepId]?.answers[
          actionPayload.inputId
        ]
      ) {
        state.stepAnswers[actionPayload.stepId].answers[actionPayload.inputId] =
          actionPayload.inputAnswer;
        // Check if step answered
        let answered = true;
        Object.values(state.stepAnswers[actionPayload.stepId].answers).forEach(
          (sa) => {
            if (!sa.optionId?.length && !sa.value) {
              answered = false;
            }
          },
        );
        state.stepAnswers[actionPayload.stepId].answered = answered;
      }
      return { ...state, stepAnswers: { ...state.stepAnswers } };
    }

    case ActionTypes.RESET_ANSWERS: {
      if (state.stepAnswers !== null) {
        Object.keys(state.stepAnswers).forEach((stepId) => {
          if (state.stepAnswers?.[stepId as unknown as number]) {
            const newStepAnswers: StepAnswers = {
              answers: state.stepAnswers[stepId as unknown as number].answers,
              answered: false,
            };
            if (state.stepAnswers[stepId as unknown as number].lead) {
              newStepAnswers.lead =
                state.stepAnswers[stepId as unknown as number].lead;
            }
            Object.keys(newStepAnswers.answers).forEach((inputId) => {
              if (newStepAnswers.answers[inputId as unknown as number]) {
                newStepAnswers.answers[inputId as unknown as number] = {
                  optionId: null,
                  value: null,
                };
              }
            });
            state.stepAnswers[stepId as unknown as number] = newStepAnswers;
          }
        });
      }
      return { ...state, ...state.stepAnswers };
    }

    case ActionTypes.RECORD_LEAD: {
      if (state.stepAnswers) {
        const actionPayload = action.payload as ActionRecordLeadPayload;
        Object.keys(state.stepAnswers).forEach((stepId) => {
          if (state.stepAnswers?.[stepId as unknown as number]?.answers) {
            const step: StepAnswers = {
              ...state.stepAnswers?.[stepId as unknown as number],
            };
            if (step.lead) {
              switch (actionPayload.type) {
                case 'firstName': {
                  step.lead = { ...step.lead, firstName: actionPayload.value };
                  break;
                }
                case 'lastName': {
                  step.lead = { ...step.lead, lastName: actionPayload.value };
                  break;
                }
                case 'address': {
                  step.lead = { ...step.lead, address: actionPayload.value };
                  break;
                }
                case 'phoneNumber': {
                  step.lead = {
                    ...step.lead,
                    phoneNumber: actionPayload.value,
                  };
                  break;
                }
                case 'email': {
                  step.lead = { ...step.lead, email: actionPayload.value };
                  break;
                }
                case 'city': {
                  step.lead = { ...step.lead, city: actionPayload.value };
                  break;
                }
                case 'zip': {
                  step.lead = { ...step.lead, zip: actionPayload.value };
                  break;
                }
                case 'businessName': {
                  step.lead = {
                    ...step.lead,
                    businessName: actionPayload.value,
                  };
                  break;
                }
                case 'additionalNotes': {
                  step.lead = {
                    ...step.lead,
                    additionalNotes: actionPayload.value,
                  };
                  break;
                }
              }

              let isValid = true;
              if (step.lead) {
                Object.keys(step.lead).forEach((lk) => {
                  const lv = (step.lead as any)[lk];
                  switch (lk) {
                    case 'phoneNumber':
                      if (state.settings?.requirePhone) {
                        if (!lv || lv === null) {
                          isValid = false;
                        }
                      }
                      break;
                    case 'address':
                      if (state.settings?.requireAddress) {
                        if (!lv || lv === null) {
                          isValid = false;
                        }
                      }
                      break;
                    case 'city':
                      if (state.settings?.requireAddress) {
                        if (!lv || lv === null) {
                          isValid = false;
                        }
                      }
                      break;
                    case 'zip':
                      if (state.settings?.requireAddress) {
                        if (!lv || lv === null) {
                          isValid = false;
                        }
                      }
                      break;
                    case 'businessName':
                      if (state.settings?.requireBusinessName) {
                        if (!lv || lv === null) {
                          isValid = false;
                        }
                      }
                      break;
                    case 'additionalNotes':
                      break;
                    default:
                      if (!lv || lv === null) {
                        isValid = false;
                      }
                      break;
                  }
                });
              } else {
                isValid = false;
              }

              if (isValid) {
                step.answered = true;
              } else {
                step.answered = false;
              }
            }
            if (
              state.stepAnswers[stepId as unknown as number].lead &&
              step.lead
            ) {
              state.stepAnswers[stepId as unknown as number].lead = {
                ...step.lead,
              };
            }
            state.stepAnswers[stepId as unknown as number] = { ...step };
          }
        });
        return { ...state };
      }
      break;
    }

    default:
      return state;
  }
  return state;
}

export default formReducer;
