import { RootState } from './reducers/types/root.types.d';

export const getFormStateSelector = (state: RootState) => {
  return state.form;
};

export const getResponsesStateSelector = (state: RootState) => {
  return state.responses;
};

export const getResponseStateSelector = (key: string) => (state: RootState) => {
  return state.responses.responses[key];
};

export const getUIStateSelector = (state: RootState) => {
  return state.ui;
};

export const getCurrentFormStep = (state: RootState) => {
  return state.form.currentStep;
};

export const getFormStepAnswers = (state: RootState) => {
  return state.form.stepAnswers;
};

export const getIsFormOnFirstStep = (state: RootState) => {
  return state.form.isOnFirstStep;
};

export const getFormLeadAnswers = (state: RootState) => {
  if (state.form.stepAnswers) {
    let finalStepId = null;
    Object.keys(state.form.stepAnswers).forEach((stepId) => {
      const step =
        state.form.stepAnswers?.[stepId as unknown as number] || null;
      if (step?.lead) {
        finalStepId = stepId;
      }
    });
    if (finalStepId !== null) {
      return state.form.stepAnswers[finalStepId].lead;
    }
  }
};

export const getAnimatingComponents = (state: RootState) => {
  return state.ui.componentsAnimating;
};

export const getFormTranslations = (state: RootState) => {
  return state.form.translations;
};

export const getFormDemoSubmission = (state: RootState) => {
  return state.form.demoSubmission;
};

export const getFormStyles = (state: RootState) => {
  return state.form?.loadClientConfiguration?.style ?? null;
};

export const getPlatformSettings = (state: RootState) => {
  return state.form?.loadClientConfiguration?.platformSettings ?? null;
};

export const getDateFormat = (state: RootState) => {
  return state.form?.loadClientConfiguration?.settings?.dateFormat;
};
