import './index.scss';
import React, {
  HTMLInputTypeAttribute,
  FocusEventHandler,
  KeyboardEventHandler,
} from 'react';
import { CheckmarkIcon } from '../../assets/images/icons';
import { useInputFocused } from '../../hooks/use-input-focused';
import { motion } from 'framer-motion';
import ParentAppService from '../../services/parentApp';
import { validateEmail } from '../../validators';
import { Tooltip } from 'antd';

export type InputPropsError = string[];

export interface InputProps {
  defaultValue?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  className?: string;
  name?: string;
  For?: string;
  label?: string;
  errors?: InputPropsError;
  valid?: boolean;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
  motionDelay?: number;
  motionDuration?: number;
  autoComplete?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  variant?: 'default' | 'large';
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  defaultFocused?: boolean;
  tooltip?: string;
}

function Input({
  defaultValue = '',
  placeholder,
  label,
  type,
  className = '',
  name = ParentAppService.generateUUID(),
  errors = [],
  valid = false,
  onChange,
  style,
  motionDelay = 0,
  motionDuration = 0.45,
  autoComplete,
  variant = 'default',
  onBlur,
  onKeyUp,
  defaultFocused,
  tooltip = '',
}: InputProps) {
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const focused = useInputFocused(inputRef);

  const [value, setValue] = React.useState(defaultValue);
  const [_errors, _setErrors] = React.useState<InputPropsError>([]);

  const isValid = valid && !errors.length && !_errors.length;

  React.useEffect(() => {
    if (defaultFocused && inputRef.current) {
      setTimeout(() => {
        if (inputRef.current?.focus) {
          inputRef.current.focus();
        }
      }, motionDelay + motionDuration * 1000);
    }
  }, []);

  const isInvalidValue = (val: string) => {
    if (type === 'email') {
      const emailCorrect = validateEmail(val);
      if (!emailCorrect) {
        return ['Email is not valid'];
      }
    }
    if (!val.length) {
      return ['Please enter a valid value'];
    }
    return false;
  };

  const errorsToUse = errors?.length ? errors : _errors;
  const errorsLength = errorsToUse.length;

  return (
    <Tooltip
      title={errorsToUse?.[0]}
      open={!!errorsLength}
      placement="topLeft"
      overlayClassName={`input-component-tooltip`}
    >
      <motion.div
        className={`input-component-container ${className} ${
          focused ? 'focused' : ''
        } ${variant}`}
        style={style}
        initial={{ opacity: 0, translateY: '30px' }}
        animate={{ opacity: 1, translateY: '0%' }}
        exit={{ opacity: 0, transition: { delay: 0 } }}
        transition={{
          duration: motionDuration,
          delay: motionDelay,
          ease: 'anticipate',
        }}
      >
        <input
          // autoFocus={defaultFocused}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e);
            }
            const invalid = isInvalidValue(value);
            if (!invalid) {
              _setErrors([]);
            } else {
              _setErrors(invalid);
            }
          }}
          ref={inputRef}
          data-toggle="tooltip"
          data-placement="top"
          title={tooltip}
          value={value}
          placeholder={placeholder}
          type={type === 'number' ? 'number' : 'text'}
          onChange={(event) => {
            _setErrors([]);
            setValue(event.target.value);
            if (onChange) {
              if (!isInvalidValue(event.target.value)) {
                onChange(event.target.value);
              } else {
                onChange('');
              }
            }
          }}
          autoComplete={autoComplete}
          className={`input-component-input ${
            errorsLength ? 'error' : ''
          } ${variant}`}
          name={name}
          id={name}
          onKeyUp={onKeyUp}
          inputMode={type === 'number' ? 'numeric' : 'text'}
        />
        {label ? (
          <label
            className={`input-component-label ${focused ? 'focused' : ''} ${
              value.length ? 'has-value' : ''
            } ${errors.length && focused ? 'error' : ''} ${variant}`}
            htmlFor={name}
          >
            {label}
          </label>
        ) : null}
        <span
          className={`input-component-valid ${
            isValid ? 'active' : 'inactive'
          } ${variant}`}
        >
          <CheckmarkIcon />
        </span>
      </motion.div>
    </Tooltip>
  );
}

export default Input;
