import React from 'react';
import { motion } from 'framer-motion';
import { FormsLoadClientStepOption } from '../../services/api/types/forms.responses';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionRemoveAnimatingComponents,
  actionUpdateAnimatingComponents,
} from '../../store/actions/ui.actions';
import styles from './index.module.scss';
import { getAnimatingComponents } from '../../store/selectors';
import APIService from '../../services/api/index.api';
import { CheckmarkIcon } from '../../assets/images/icons';
import { ViewType } from '../../services/api/types/input';

export interface SelectSmallProps {
  inputId: number;
  className?: string;
  style?: React.CSSProperties;
  options: FormsLoadClientStepOption[];
  initialDelay?: number;
  activeOptionIds?: number[] | null;
  onSelect?: (option: FormsLoadClientStepOption) => void;
  multiple?: boolean;
  viewType: ViewType;
}

function SelectSmall({
  className = '',
  style,
  options,
  initialDelay = 0,
  activeOptionIds = null,
  onSelect,
  multiple = false,
  viewType,
}: SelectSmallProps) {
  const dispatch = useDispatch();
  const componentsAnimating = useSelector(getAnimatingComponents);

  return (
    <div
      className={`${styles.container} ${className} ${
        styles[`${viewType}Container`]
      }`}
      style={style}
    >
      {options.map((option, index) => {
        const dotRef =
          React.createRef() as React.MutableRefObject<HTMLSpanElement>;
        const dotInnerRef =
          React.createRef() as React.MutableRefObject<HTMLSpanElement>;
        const isOptionSelected = activeOptionIds?.includes(option.id);
        return (
          <motion.div
            key={option.id}
            className={`${styles.option} ${styles[`${viewType}Option`]}`}
            style={{ order: option.order }}
            initial={{
              opacity: 0,
              translateY: '15%',
            }}
            onLoad={() => {
              dispatch(
                actionUpdateAnimatingComponents({
                  components: ['options'],
                }),
              );
            }}
            onAnimationComplete={() => {
              if (componentsAnimating.includes('options')) {
                dispatch(
                  actionRemoveAnimatingComponents({
                    components: ['options'],
                  }),
                );
              }
            }}
            animate={{
              opacity: 1,
              translateY: '0%',
              transition: {
                duration: 0.7,
                delay: initialDelay + 0.35 + index * 0.05,
                ease: 'anticipate',
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                duration: 0.4,
                delay: 0,
                ease: 'anticipate',
              },
              pointerEvents: 'none',
            }}
            onClick={() => {
              if (onSelect) {
                onSelect(option);
              }
              if (dotRef.current && dotInnerRef.current) {
                dotRef.current.classList.add(styles.optionActive);
                dotInnerRef.current.classList.add(styles.optionActive);
              }
            }}
          >
            <div
              className={`${styles.optionHolder} ${
                isOptionSelected ? styles.optionActive : ''
              } ${option.imageId ? styles.optionHolderWithImage : ''}`}
            >
              {/* <span
                className={`single-select-no-icon-component-option-dot ${
                  isOptionSelected ? 'active' : ''
                } ${multiple ? 'multiple' : ''}`}
                ref={dotRef as any}
              >
                <span
                  ref={dotInnerRef as any}
                  className={`single-select-no-icon-component-option-dot-inner ${
                    isOptionSelected ? 'active' : ''
                  } ${multiple ? 'multiple' : ''}`}
                />
              </span> */}
              {option.imageId ? (
                <div className={styles.imageHolder}>
                  <img
                    className={styles.image}
                    src={APIService.imagesService.createImageUrl(
                      option.imageId,
                    )}
                    onLoad={(e: any) =>
                      e.target.classList.add(styles.imageLoaded)
                    }
                  />
                  <div className={styles.imageBackground} />
                </div>
              ) : (
                <div className={styles.noImageSpacer} />
              )}
              <span className={styles.optionLabel}>{option.value}</span>
              {multiple ? (
                <div
                  className={`${styles.multipleCheckbox} ${
                    isOptionSelected ? styles.multipleCheckboxActive : ''
                  }`}
                >
                  <CheckmarkIcon
                    className={`${styles.multipleCheckboxIcon} ${
                      isOptionSelected ? styles.multipleCheckboxIconActive : ''
                    }`}
                  />
                </div>
              ) : null}
            </div>
          </motion.div>
        );
      })}
    </div>
  );
}

export default SelectSmall;
