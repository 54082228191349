import React, { KeyboardEventHandler, useEffect } from 'react';
import { motion } from 'framer-motion';
import ParentAppService from '../../services/parentApp';
import styles from './index.module.scss';
import { DatePickerIcon } from '../../assets/images/icons';
import { useSelector } from 'react-redux';
import { getDateFormat } from '../../store/selectors';
import useClickOutside from '../../hooks/use-click-outside';

export type DatePickerError = string[];

export interface DatePickerProps {
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  name?: string;
  label?: string;
  errors?: DatePickerError;
  valid?: boolean;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
  motionDelay?: number;
  motionDuration?: number;
  variant?: 'default' | 'large';
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  defaultFocused?: boolean;
  format?: string;
  tooltip?: string;
  focusTrigger?: symbol | null;
}

function DatePicker({
  defaultValue = '',
  label,
  className = '',
  name = ParentAppService.generateUUID(),
  errors = [],
  valid = false,
  onChange,
  style,
  motionDelay = 0,
  motionDuration = 0.45,
  variant = 'default',
  onKeyUp,
  defaultFocused,
  format,
  focusTrigger,
  onKeyDown,
}: DatePickerProps) {
  const inputRefFirst =
    React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const inputRefSecond =
    React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const inputRefThird =
    React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const holderRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [inputValue, setInputValue] = React.useState<string>(defaultValue);
  const [hiddenInputFocused, setHiddenInputFocused] =
    React.useState<boolean>(false);
  const firstTime = React.useRef(true);
  const firstTimeValue = React.useRef(true);

  const [inputsFocused, setInputsFocused] = React.useState<{
    first: boolean;
    second: boolean;
    third: boolean;
  }>({
    first: false,
    second: false,
    third: false,
  });
  const inputFocused =
    inputsFocused.first || inputsFocused.second || inputsFocused.third;

  const dateFormatFromForm = useSelector(getDateFormat);
  const dateFormat = format ?? dateFormatFromForm ?? 'MM/DD/YYYY';
  const dateFormatData = (() => {
    const splitter = dateFormat.includes('/')
      ? '/'
      : dateFormat.includes('-')
      ? '-'
      : '.';
    const [first, second, third] = dateFormat.split(splitter);
    return { first, second, third, splitter };
  })();

  const [value, setValue] = React.useState<{
    first: string;
    second: string;
    third: string;
  }>(getValueFromDefaultValue());
  const hasAnyValue = value.first || value.second || value.third;

  function getInputValue() {
    const iterator: any = { ...dateFormatData };
    delete iterator.splitter;
    const yearIndex = Object.keys(iterator).find(
      (key) => (dateFormatData as any)[key] === 'YYYY',
    );
    const monthIndex = Object.keys(iterator).find(
      (key) => (dateFormatData as any)[key] === 'MM',
    );
    const dayIndex = Object.keys(iterator).find(
      (key) => (dateFormatData as any)[key] === 'DD',
    );
    const yearValue = yearIndex ? (value as any)[yearIndex] : '';
    const monthValue = monthIndex ? (value as any)[monthIndex] : '';
    const dayValue = dayIndex ? (value as any)[dayIndex] : '';
    const yearFormat = yearIndex ? (dateFormatData as any)[yearIndex] : '';
    const monthFormat = monthIndex ? (dateFormatData as any)[monthIndex] : '';
    const dayFormat = dayIndex ? (dateFormatData as any)[dayIndex] : '';

    if (
      yearValue.length === yearFormat.length &&
      monthValue &&
      monthValue.length === monthFormat.length &&
      dayValue.length === dayFormat.length
    ) {
      return `${yearValue}-${monthValue}-${dayValue}`;
    }
    return '';
  }

  function getValueFromDefaultValue() {
    const value = defaultValue.split('-');
    const year = value[0];
    const month = value[1];
    const day = value[2];

    const yearIndex = Object.keys(dateFormatData).find(
      (key) => (dateFormatData as any)[key] === 'YYYY',
    );
    const monthIndex = Object.keys(dateFormatData).find(
      (key) => (dateFormatData as any)[key] === 'MM',
    );
    const dayIndex = Object.keys(dateFormatData).find(
      (key) => (dateFormatData as any)[key] === 'DD',
    );

    if (yearIndex && monthIndex && dayIndex && year && month && day) {
      return {
        [yearIndex as any]: year,
        [monthIndex as any]: month,
        [dayIndex as any]: day,
      } as any;
    }
    return {
      first: '',
      second: '',
      third: '',
    };
  }

  React.useEffect(() => {
    if (defaultFocused && inputRef.current) {
      inputRef.current.click();
      setTimeout(() => {
        if (!defaultValue) {
          focusFirstInput();
        } else {
          focusThirdInput();
        }
      }, motionDelay + motionDuration * 1000);
    }
  }, []);

  function unfocusInputs(unfocusElement = true) {
    if (inputRef.current && unfocusElement) {
      inputRef.current.blur();
    }
    setInputsFocused({
      first: false,
      second: false,
      third: false,
    });
  }
  useClickOutside(holderRef, () => {
    unfocusInputs();
  });

  function focusFirstInput() {
    if (inputRef.current && !hiddenInputFocused) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    setInputsFocused({
      first: true,
      second: false,
      third: false,
    });
  }

  function focusSecondInput() {
    if (inputRef.current && !hiddenInputFocused) {
      inputRef.current.select();
    }
    setInputsFocused({
      first: false,
      second: true,
      third: false,
    });
  }

  function focusThirdInput() {
    if (inputRef.current && !hiddenInputFocused) {
      inputRef.current.select();
    }
    setInputsFocused({
      first: false,
      second: false,
      third: true,
    });
  }

  function checkIfKeyValid(key: string, existingValue: string, format: string) {
    const isNumber = !isNaN(parseInt(key, 10));
    if (!isNumber) return [false, existingValue];
    let isValid = false;
    let newValue = existingValue + key;
    if (
      format === 'MM' &&
      newValue.length === 1 &&
      parseInt(newValue, 10) > 1
    ) {
      newValue = `0${newValue}`;
    }
    if (
      format === 'DD' &&
      newValue.length === 1 &&
      parseInt(newValue, 10) > 3
    ) {
      newValue = `0${newValue}`;
    }
    if (format === 'MM') {
      isValid = parseInt(newValue, 10) <= 12;
    }
    if (format === 'DD') {
      isValid = parseInt(newValue, 10) <= 31;
    }
    if (format === 'YYYY') {
      isValid = newValue.length <= 4;
    }
    return [isValid, newValue];
  }

  useEffect(() => {
    const _firstInputHandler = (event: KeyboardEvent) => {
      let existingValue = value.first;
      const format = dateFormatData.first;
      const isNumber = !isNaN(parseInt(event.key, 10));
      const isDelete = event.key === 'Backspace';
      const isShiftTab = event.key === 'Tab' && event.shiftKey;
      const isTab = !isShiftTab && event.key === 'Tab';
      const isArrowRight = event.key === 'ArrowRight';
      const moveRight = isTab || isArrowRight;

      if (moveRight) {
        focusSecondInput();
        return;
      }
      let isValid = false;
      let newValue = existingValue;
      if (isDelete) {
        newValue = existingValue.slice(0, -1);
        isValid = true;
      } else if (isNumber && existingValue.length < format.length) {
        newValue = `${existingValue}${event.key}`;
        if (
          format === 'MM' &&
          newValue.length === 1 &&
          parseInt(newValue, 10) > 1
        ) {
          newValue = `0${newValue}`;
        }
        if (
          format === 'DD' &&
          newValue.length === 1 &&
          parseInt(newValue, 10) > 3
        ) {
          newValue = `0${newValue}`;
        }
        if (format === 'MM') {
          isValid = parseInt(newValue, 10) <= 12;
        }
        if (format === 'DD') {
          isValid = parseInt(newValue, 10) <= 31;
        }
        if (format === 'YYYY') {
          isValid = newValue.length <= 4;
        }
        if (isValid && newValue.length === format.length) {
          focusSecondInput();
        }
      } else if (
        existingValue !== format &&
        existingValue.length === format.length &&
        checkIfKeyValid(event.key, value.second, dateFormatData.second)[0]
      ) {
        setValue({
          ...value,
          second: checkIfKeyValid(
            event.key,
            value.second,
            dateFormatData.second,
          )[1] as string,
        });
        focusSecondInput();
      }

      if (isValid) {
        existingValue = newValue;
        setValue({
          ...value,
          first: newValue,
        });
      }
    };
    const _secondInputHandler = (event: KeyboardEvent) => {
      let existingValue = value.second;
      const format = dateFormatData.second;
      const isNumber = !isNaN(parseInt(event.key, 10));
      const isDelete = event.key === 'Backspace';
      const isShiftTab = event.key === 'Tab' && event.shiftKey;
      const isTab = !isShiftTab && event.key === 'Tab';
      const isArrowRight = event.key === 'ArrowRight';
      const isArrowLeft = event.key === 'ArrowLeft';
      const moveRight = isTab || isArrowRight;
      const moveLeft = isShiftTab || isArrowLeft;

      if (moveRight) {
        focusThirdInput();
        return;
      }
      if (moveLeft) {
        focusFirstInput();
        return;
      }

      let isValid = false;
      let newValue = existingValue;
      if (isDelete) {
        newValue = existingValue.slice(0, -1);
        isValid = true;
        if (newValue.length === 0) {
          setValue({
            ...value,
            first:
              existingValue === '' ? value.first.slice(0, -1) : value.first,
            second: '',
          });
          focusFirstInput();
          return;
        }
      } else if (isNumber && existingValue.length < format.length) {
        newValue = `${existingValue}${event.key}`;
        if (
          format === 'MM' &&
          newValue.length === 1 &&
          parseInt(newValue, 10) > 1
        ) {
          newValue = `0${newValue}`;
        }
        if (
          format === 'DD' &&
          newValue.length === 1 &&
          parseInt(newValue, 10) > 3
        ) {
          newValue = `0${newValue}`;
        }
        if (format === 'MM') {
          isValid = parseInt(newValue, 10) <= 12;
        }
        if (format === 'DD') {
          isValid = parseInt(newValue, 10) <= 31;
        }
        if (format === 'YYYY') {
          isValid = newValue.length <= 4;
        }
        if (isValid && newValue.length === format.length) {
          focusThirdInput();
        }
      } else if (
        existingValue !== format &&
        existingValue.length === format.length &&
        checkIfKeyValid(event.key, value.third, dateFormatData.third)[0]
      ) {
        setValue({
          ...value,
          third: checkIfKeyValid(
            event.key,
            value.third,
            dateFormatData.third,
          )[1] as string,
        });
        focusThirdInput();
      }
      if (isValid) {
        existingValue = newValue;
        setValue({
          ...value,
          second: newValue,
        });
      }
    };
    const _thirdInputHandler = (event: KeyboardEvent) => {
      let existingValue = value.third;
      const format = dateFormatData.third;
      const isNumber = !isNaN(parseInt(event.key, 10));
      const isDelete = event.key === 'Backspace';
      const isShiftTab = event.key === 'Tab' && event.shiftKey;
      const isArrowLeft = event.key === 'ArrowLeft';
      const moveLeft = isShiftTab || isArrowLeft;

      if (moveLeft) {
        focusSecondInput();
        return;
      }
      let isValid = false;
      let newValue = existingValue;
      if (isDelete) {
        newValue = existingValue.slice(0, -1);
        isValid = true;
        if (newValue.length === 0) {
          setValue({
            ...value,
            second:
              existingValue === '' ? value.second.slice(0, -1) : value.second,
            third: '',
          });
          focusSecondInput();
          return;
        }
      } else if (isNumber && existingValue.length < format.length) {
        newValue = `${existingValue}${event.key}`;
        if (
          format === 'MM' &&
          newValue.length === 1 &&
          parseInt(newValue, 10) > 1
        ) {
          newValue = `0${newValue}`;
        }
        if (
          format === 'DD' &&
          newValue.length === 1 &&
          parseInt(newValue, 10) > 3
        ) {
          newValue = `0${newValue}`;
        }
        if (format === 'MM') {
          isValid = parseInt(newValue, 10) <= 12;
        }
        if (format === 'DD') {
          isValid = parseInt(newValue, 10) <= 31;
        }
        if (format === 'YYYY') {
          isValid = newValue.length <= 4;
        }
        if (isValid && newValue.length === format.length) {
          // return;
        }
      }

      if (isValid) {
        existingValue = newValue;
        setValue({
          ...value,
          third: newValue,
        });
      }
    };

    function _onEnter(event: KeyboardEvent) {
      if (event.key === 'Enter' && onKeyUp) {
        onKeyUp(event as any);
      }
    }

    if (inputsFocused.first) {
      window.addEventListener('keydown', _firstInputHandler);
      window.addEventListener('keyup', _onEnter);
    } else if (inputsFocused.second) {
      window.addEventListener('keydown', _secondInputHandler);
      window.addEventListener('keyup', _onEnter);
    } else if (inputsFocused.third) {
      window.addEventListener('keydown', _thirdInputHandler);
      window.addEventListener('keyup', _onEnter);
    }

    return () => {
      window.removeEventListener('keydown', _firstInputHandler);
      window.removeEventListener('keydown', _secondInputHandler);
      window.removeEventListener('keydown', _thirdInputHandler);
      window.removeEventListener('keyup', _onEnter);
    };
  }, [inputsFocused, value, onKeyUp]);

  useEffect(() => {
    if (firstTimeValue.current) {
      firstTimeValue.current = false;
      return;
    }
    if (onChange) {
      onChange(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    const inputValueNew = getInputValue();
    if (inputValueNew !== inputValue) {
      setInputValue(inputValueNew);
    }
  }, [value]);

  useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false;
      return;
    }
    if (focusTrigger) {
      if (!value.first) {
        focusFirstInput();
      } else if (!value.second) {
        focusSecondInput();
      } else {
        focusThirdInput();
      }
    }
  }, [focusTrigger]);

  return (
    <motion.div
      className={`${styles.container} ${className} ${
        inputFocused ? styles.containerFocused : ''
      } ${styles[`${variant}Container`]}`}
      style={style}
      initial={{ opacity: 0, translateY: '30px' }}
      animate={{ opacity: 1, translateY: '0%' }}
      exit={{ opacity: 0, transition: { delay: 0 } }}
      transition={{
        duration: motionDuration,
        delay: motionDelay,
        ease: 'anticipate',
      }}
    >
      <div
        ref={holderRef}
        className={`${styles.holder} ${styles[`${variant}Holder`]} ${
          errors?.length ? styles.holderError : ''
        } ${inputFocused ? styles.holderFocused : ''}`}
        onClick={(e) => {
          const isClickOnAnyInput =
            e.target === inputRefFirst.current ||
            e.target === inputRefSecond.current ||
            e.target === inputRefThird.current;
          const noValue = !value.first && !value.second && !value.third;
          if (!inputFocused && (!isClickOnAnyInput || noValue)) {
            if (!value.first) {
              focusFirstInput();
            } else if (!value.second) {
              focusSecondInput();
            } else {
              focusThirdInput();
            }
          }
        }}
        onMouseDown={(event) => {
          holderRef.current?.classList.add(styles.holderActive);
        }}
        onMouseUp={(event) => {
          holderRef.current?.classList.remove(styles.holderActive);
        }}
      >
        {hiddenInputFocused}
        <div
          ref={inputRefFirst}
          className={`${styles.input} ${styles.inputFirst} ${
            styles[`${variant}Input`]
          } ${styles[`${dateFormatData.first}Input`]} ${
            hasAnyValue ? styles.inputHasValue : ''
          } ${inputFocused ? styles.inputFocused : ''} ${
            dateFormatData.first === 'YYYY' ? styles.inputYear : ''
          } ${value.first.length ? styles.hasOwnValue : ''}`}
          onClick={() => {
            focusFirstInput();
          }}
          style={{
            borderLeft:
              inputsFocused.first && value.first.length === 0
                ? '1px solid black'
                : 'none',
            borderRight:
              inputsFocused.first && value.first.length > 0
                ? '1px solid black'
                : 'none',
          }}
        >
          {value.first || dateFormatData.first}
        </div>
        <span
          className={`${styles.splitter} ${styles[`${variant}Splitter`]} ${
            hasAnyValue ? styles.splitterHasValue : ''
          } ${inputFocused ? styles.splitterFocused : ''}`}
        >
          {dateFormatData.splitter}
        </span>
        <div
          ref={inputRefSecond}
          className={`${styles.input} ${styles.inputSecond} ${
            styles[`${variant}Input`]
          } ${styles[`${dateFormatData.second}Input`]} ${
            hasAnyValue ? styles.inputHasValue : ''
          } ${inputFocused ? styles.inputFocused : ''} ${
            dateFormatData.second === 'YYYY' ? styles.inputYear : ''
          } ${value.second.length ? styles.hasOwnValue : ''}`}
          onClick={() => {
            focusSecondInput();
          }}
          style={{
            borderLeft:
              inputsFocused.second && value.second.length === 0
                ? '1px solid black'
                : 'none',
            borderRight:
              inputsFocused.second && value.second.length > 0
                ? '1px solid black'
                : 'none',
          }}
        >
          {value.second || dateFormatData.second}
        </div>
        <span
          className={`${styles.splitter} ${styles[`${variant}Splitter`]} ${
            hasAnyValue ? styles.splitterHasValue : ''
          } ${inputFocused ? styles.splitterFocused : ''}`}
        >
          {dateFormatData.splitter}
        </span>
        <div
          ref={inputRefThird}
          className={`${styles.input} ${styles.inputThird} ${
            styles[`${variant}Input`]
          } ${styles[`${dateFormatData.third}Input`]} ${
            hasAnyValue ? styles.inputHasValue : ''
          } ${inputFocused ? styles.inputFocused : ''} ${
            value.third.length ? styles.hasOwnValue : ''
          }`}
          onClick={() => {
            focusThirdInput();
          }}
          style={{
            borderLeft:
              inputsFocused.third && value.third.length === 0
                ? '1px solid black'
                : 'none',
            borderRight:
              inputsFocused.third && value.third.length > 0
                ? '1px solid black'
                : 'none',
          }}
        >
          {value.third || dateFormatData.third}
        </div>
        {label ? (
          <label
            className={`${styles.label} ${
              inputFocused ? styles.labelFocused : ''
            } ${hasAnyValue ? styles.labelHasValue : ''} ${
              errors.length && inputFocused ? styles.labelError : ''
            } ${styles[`${variant}Label`]}`}
            htmlFor={name}
          >
            {label}
          </label>
        ) : null}
        <input
          ref={inputRef}
          type="text"
          inputMode="numeric"
          name={name}
          autoFocus={defaultFocused}
          value={inputValue}
          className={styles.hiddenInput}
          onFocus={(e) => {
            setHiddenInputFocused(true);
            if (!inputFocused) {
              if (!value.first) {
                focusFirstInput();
              } else if (!value.second) {
                focusSecondInput();
              } else {
                focusThirdInput();
              }
            }
          }}
          onBlur={(e) => {
            unfocusInputs(false);
            setHiddenInputFocused(false);
          }}
          onKeyDown={(e) => {
            if (onKeyDown) {
              onKeyDown(e);
            }
          }}
        />
        <DatePickerIcon className={styles.icon} />
      </div>
    </motion.div>
  );
}

export default DatePicker;
