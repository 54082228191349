export const TAPFORM_WINDOW_MESSAGE_KEY_PREFIX = 'tapform-app';

export function createMessageKey(formToken: string | null) {
  if (!formToken) {
    return TAPFORM_WINDOW_MESSAGE_KEY_PREFIX;
  }
  return `${TAPFORM_WINDOW_MESSAGE_KEY_PREFIX}-${formToken}`;
}

export function checkIfValidTapformMessageKey(
  messageKey: string,
  formToken?: string | null,
) {
  if (!formToken) {
    return messageKey.startsWith(TAPFORM_WINDOW_MESSAGE_KEY_PREFIX);
  }
  const expectedMessageKey = createMessageKey(formToken);
  return messageKey === expectedMessageKey;
}
