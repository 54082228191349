import React from 'react';
import { motion } from 'framer-motion';
import styles from './index.module.scss';
import Logo from './assets/tf-logo.webp';
import { useSelector } from 'react-redux';
import { getFormStyles } from '../../store/selectors';

interface WatermarkPillProps {
  className?: string;
  text?: string;
  href?: string;
}

function WatermarkPill({
  className = '',
  text = 'Create your Tapform',
  href = 'https://dashboard.tapform.io/signup',
}: WatermarkPillProps) {
  const style = useSelector(getFormStyles);
  if (style?.disableBranding) {
    return null;
  }

  return (
    <a
      className={`${styles.container} ${className}`}
      href={href}
      target="blank"
    >
      <img className={styles.logo} src={Logo} />
      {text}
    </a>
  );
}

export default WatermarkPill;
