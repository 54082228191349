import React, { FocusEventHandler } from 'react';
import useClickOutside from '../../hooks/use-click-outside';
import { getDefaultPhoneCodeOption, PhoneCode, PHONE_CODES } from './codes';
import {
  CheckmarkIcon,
  DropdownIcon,
  PhoneCodeOptionCheckmark,
  SearchIcon,
} from '../../assets/images/icons';
import './index.scss';
import { useInputFocused } from '../../hooks/use-input-focused';
import { checkIfAllowedPhoneNumberCharacter } from '../../validators';
import { motion } from 'framer-motion';
import { Tooltip } from 'antd';

export type InputPropsError = string[];

export interface PhoneInputProps {
  onChange?: (phoneNumber: string) => void;
  className?: string;
  errors?: InputPropsError;
  valid?: boolean;
  name?: string;
  defaultValue?: string;
  motionDelay?: number;
  motionDuration?: number;
  label?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

function PhoneInput({
  onChange,
  className = '',
  errors = [],
  valid = false,
  name = 'phone-number-input',
  defaultValue = '',
  motionDelay = 0,
  motionDuration = 0.45,
  label = 'Phone Number',
  onBlur,
  onKeyUp,
}: PhoneInputProps) {
  const [selectedCode, setSelectedCode] = React.useState<PhoneCode>(
    getDefaultPhoneCodeOption(),
  );
  const [value, setValue] = React.useState(
    defaultValue || `${getDefaultPhoneCodeOption().dial_code} `,
  );
  const [searchValue, setSearchValue] = React.useState('');
  const [dropdownActive, setDropdownActive] = React.useState(false);
  const searchOptionsRef =
    React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const phoneInputRef =
    React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const phoneDropdownMobileShadow =
    React.useRef() as React.MutableRefObject<HTMLInputElement>;

  React.useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  const closeDropdown = () => {
    setDropdownActive(false);
    setSearchValue('');
    phoneInputRef.current.focus();
  };

  const openDropdown = () => {
    setDropdownActive(true);
    setTimeout(() => {
      searchOptionsRef.current.focus();
    }, 0);
  };

  const changePhoneCode = (newPhoneCode: PhoneCode) => {
    const phoneNumberPart = value.substring(selectedCode.dial_code.length + 1);
    setValue(`${newPhoneCode.dial_code} ${phoneNumberPart}`);
    setSelectedCode(newPhoneCode);
    closeDropdown();
  };

  const searchOptions = React.useMemo(() => {
    if (searchValue.length >= 2) {
      return PHONE_CODES.filter((pc) =>
        pc.name.toLowerCase().includes(searchValue.toLowerCase()),
      );
    } else return PHONE_CODES;
  }, [searchValue]);

  const inputContainerRef =
    React.useRef() as React.MutableRefObject<HTMLDivElement>;

  useClickOutside(inputContainerRef, () => {
    if (window.visualViewport && window.visualViewport.width > 1024) {
      setDropdownActive(false);
      setSearchValue('');
    }
  });

  const phoneInputFocused = useInputFocused(phoneInputRef) || dropdownActive;

  const isValid = valid && !errors.length;

  React.useEffect(() => {
    if (phoneDropdownMobileShadow.current) {
      if (dropdownActive) {
        phoneDropdownMobileShadow.current.classList.add('active');
        setTimeout(() => {
          phoneDropdownMobileShadow.current.classList.add('active-anim');
        }, 0);
      } else {
        phoneDropdownMobileShadow.current.classList.remove('active-anim');
        setTimeout(() => {
          phoneDropdownMobileShadow.current.classList.remove('active');
        }, 250);
      }
    }
  }, [dropdownActive]);

  return (
    <>
      <Tooltip
        title={errors?.[0]}
        open={!!errors?.length}
        placement="topLeft"
        overlayClassName={`phone-input-component-tooltip`}
      >
        <motion.div
          ref={inputContainerRef}
          className={`phone-input-component-container ${className} ${
            phoneInputFocused ? 'focused' : ''
          } ${dropdownActive ? 'dropdown-active' : ''} ${
            errors?.length ? 'error' : ''
          }`}
          initial={{ opacity: 0, translateY: '30px' }}
          animate={{ opacity: 1, translateY: '0%' }}
          exit={{ opacity: 0, transition: { delay: 0 } }}
          transition={{
            duration: motionDuration,
            delay: motionDelay,
            ease: 'anticipate',
          }}
        >
          <div
            className="phone-input-component-region"
            onClick={() => {
              dropdownActive ? closeDropdown() : openDropdown();
            }}
          >
            {selectedCode.flag}
            <DropdownIcon className="phone-input-component-region-icon" />
          </div>
          <div className="phone-input-component-input-holder">
            <label
              className={`phone-input-component-input-label ${
                phoneInputFocused ? 'focused' : ''
              }`}
              htmlFor={name}
            >
              {label}
            </label>
            <input
              onBlur={onBlur}
              ref={phoneInputRef}
              className="phone-input-component-input"
              name={name}
              id={name}
              type={'tel'}
              value={value}
              autoComplete="tel"
              onChange={(e) => {
                let newStr = '';
                for (let i = 0; i < e.target.value.length; i++) {
                  const testChar = e.target.value[i];
                  if (checkIfAllowedPhoneNumberCharacter(testChar)) {
                    newStr += testChar;
                  }
                }
                PHONE_CODES.forEach((phoneCode) => {
                  if (newStr.includes(phoneCode.dial_code)) {
                    setSelectedCode(phoneCode);
                  }
                });
                setValue(newStr);
              }}
              onKeyUp={onKeyUp}
            />
            <span
              className={`phone-input-component-input-valid ${
                isValid && phoneInputFocused ? 'active' : 'inactive'
              }`}
            >
              <CheckmarkIcon />
            </span>
          </div>
          {window.visualViewport && window.visualViewport.width > 1024 ? (
            <div
              className={`phone-input-component-dropdown ${
                dropdownActive ? 'active' : ''
              }`}
            >
              <div className="phone-input-component-dropdown-top">
                <SearchIcon className="phone-input-component-dropdown-input-icon" />
                <input
                  ref={searchOptionsRef}
                  className="phone-input-component-dropdown-input"
                  name="code-search-input"
                  value={searchValue}
                  type="text"
                  onChange={(e) => {
                    setSearchValue(e.target.value || '');
                  }}
                />
              </div>
              <div className="phone-input-component-dropdown-option-container">
                {searchOptions.map((searchOption) => (
                  <div
                    key={`${searchOption.dial_code}_${searchOption.code}_phone-code-option`}
                    className={`phone-input-component-dropdown-option ${
                      selectedCode.code === searchOption.code ? 'selected' : ''
                    }`}
                    onClick={() => {
                      changePhoneCode(searchOption);
                    }}
                  >
                    <span className="phone-input-component-dropdown-option-flag">
                      {searchOption.flag}
                    </span>
                    <span className="phone-input-component-dropdown-option-text">
                      {searchOption.name} ({searchOption.dial_code})
                    </span>
                    {selectedCode.code === searchOption.code ? (
                      <PhoneCodeOptionCheckmark className="phone-input-component-dropdown-option-checkmark" />
                    ) : null}
                  </div>
                ))}
              </div>
              <div className="phone-input-component-dropdown-option-shadow" />
            </div>
          ) : null}
        </motion.div>
      </Tooltip>
      {window.visualViewport && window.visualViewport.width <= 1024 ? (
        <>
          <div
            className={`phone-input-component-dropdown-mobile ${
              dropdownActive ? 'active' : ''
            }`}
          >
            <div className="phone-input-component-dropdown-top">
              <SearchIcon className="phone-input-component-dropdown-input-icon" />
              <input
                ref={searchOptionsRef}
                className="phone-input-component-dropdown-input"
                name="code-search-input"
                value={searchValue}
                type="text"
                onChange={(e) => {
                  setSearchValue(e.target.value || '');
                }}
              />
            </div>
            <div className="phone-input-component-dropdown-option-container">
              {searchOptions.map((searchOption) => (
                <div
                  key={`${searchOption.dial_code}_${searchOption.code}_phone-code-option`}
                  className={`phone-input-component-dropdown-option ${
                    selectedCode.code === searchOption.code ? 'selected' : ''
                  }`}
                  onClick={() => {
                    changePhoneCode(searchOption);
                  }}
                >
                  <span className="phone-input-component-dropdown-option-flag">
                    {searchOption.flag}
                  </span>
                  <span className="phone-input-component-dropdown-option-text">
                    {searchOption.name} ({searchOption.dial_code})
                  </span>
                  {selectedCode.code === searchOption.code ? (
                    <PhoneCodeOptionCheckmark className="phone-input-component-dropdown-option-checkmark" />
                  ) : null}
                </div>
              ))}
            </div>
            <div className="phone-input-component-dropdown-option-shadow" />
          </div>
          <div
            ref={phoneDropdownMobileShadow}
            onClick={() => {
              setDropdownActive(false);
              setSearchValue('');
            }}
            className={`phone-input-component-dropdown-mobile-shadow`}
          />
        </>
      ) : null}
    </>
  );
}

export default PhoneInput;
