import React from 'react';
import './index.scss';

export function BackIcon(props: any) {
  return (
    <svg
      height="33"
      width="33"
      viewBox="0 0 33 33"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <rect height="33" width="33" rx="1" />
        <path
          d="M24.567 16.398a3.784 3.784 0 0 0-3.363-1.993h-10.2l3.482-4.177L13.013 9l-4.792 5.75a.962.962 0 0 0 0 1.228l4.792 5.75 1.473-1.228-3.482-4.178h10.2c.711 0 1.34.373 1.681.996.342.624.317 1.355-.012 1.877l-2.849 3.619L21.531 24l2.903-3.695a3.782 3.782 0 0 0 .133-3.907z"
          fill="#041524"
        />
      </g>
    </svg>
  );
}

export function PhoneIcon(props: any) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.45 18c-2.083 0-4.142-.454-6.175-1.363-2.033-.908-3.883-2.195-5.55-3.862-1.667-1.667-2.954-3.517-3.862-5.55C.953 5.192.5 3.133.5 1.05.5.75.6.5.8.3c.2-.2.45-.3.75-.3H5.6a.93.93 0 0 1 .625.237A.914.914 0 0 1 6.55.8l.65 3.5c.033.267.025.492-.025.675-.05.183-.142.342-.275.475L4.475 7.9c.333.617.73 1.213 1.188 1.787.458.575.962 1.13 1.512 1.663.517.517 1.058.996 1.625 1.438.567.441 1.167.845 1.8 1.212l2.35-2.35a1.4 1.4 0 0 1 .588-.338 1.61 1.61 0 0 1 .712-.062l3.45.7c.233.067.425.188.575.363a.88.88 0 0 1 .225.587v4.05c0 .3-.1.55-.3.75-.2.2-.45.3-.75.3zM3.525 6l1.65-1.65L4.75 2H2.525c.083.683.2 1.358.35 2.025.15.667.367 1.325.65 1.975zm8.95 8.95c.65.283 1.313.508 1.988.675.675.167 1.354.275 2.037.325v-2.2l-2.35-.475-1.675 1.675z"
        fill="var(--button-text-color)"
      />
    </svg>
  );
}

export interface TapformLogoProps {
  type?: 'blue' | 'white';
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<SVGSVGElement>;
  innerRef?: React.Ref<SVGSVGElement>;
}

export function TapformLogo({
  type,
  color = '#B3B8C3',
  className,
  onClick,
  style,
  innerRef,
  ...props
}: TapformLogoProps & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      ref={innerRef}
      width="111"
      height="25"
      viewBox="0 0 111 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`image-tapform-logo ${type || ''} ${className || ''}`}
      onClick={onClick}
      style={style}
    >
      <path
        d="M8.389 20.709c1.162 0 2.16-.25 3.599-1.025l-1.052-3.045c-.72.388-1.661.664-2.298.664-1.218 0-2.021-.72-2.021-2.38V9.467h4.513v-2.99H6.617v-4.18H2.962v4.18H0v2.99h2.962V15.2c0 3.793 2.16 5.51 5.427 5.51zm11.24 0c1.883 0 3.405-.554 4.568-1.495v1.08h3.655V6.477h-3.655v1.108c-1.163-.97-2.685-1.523-4.568-1.523-3.516 0-6.783 2.99-6.783 7.337 0 4.346 3.267 7.309 6.783 7.309zm.637-3.184c-2.215 0-3.876-1.578-3.876-4.125 0-2.547 1.66-4.153 3.876-4.153 1.965 0 3.931 1.606 3.931 4.153 0 2.547-1.966 4.125-3.931 4.125zM35.382 25v-5.786c1.163.941 2.686 1.495 4.568 1.495 3.516 0 6.783-2.963 6.783-7.31 0-4.346-3.267-7.336-6.783-7.336-1.882 0-3.405.554-4.568 1.523V6.478h-3.654V25h3.654zm3.931-7.475c-1.965 0-3.93-1.578-3.93-4.125 0-2.547 1.965-4.153 3.93-4.153 2.215 0 3.876 1.606 3.876 4.153 0 2.547-1.66 4.125-3.876 4.125zm14.563 2.768V9.468h4.18v-2.99h-4.18V5.233c0-1.302.858-1.994 2.049-1.994.387 0 .886.083 1.384.25l.83-2.963A5.711 5.711 0 0 0 55.677 0c-3.24 0-5.455 1.993-5.455 5.233v1.245h-2.104v2.99h2.104v10.825h3.655zm12.265.36c4.042 0 7.447-2.907 7.447-7.253 0-4.347-3.405-7.254-7.447-7.254-4.125 0-7.53 2.907-7.53 7.254 0 4.346 3.405 7.253 7.53 7.253zm0-3.128c-1.994 0-3.987-1.55-3.987-4.125s1.993-4.125 3.987-4.125c1.91 0 3.903 1.55 3.903 4.125s-1.993 4.125-3.903 4.125zm13.87 2.768v-4.457c0-3.848 1.55-5.925 4.928-5.925h.803l.194-3.516c-.332-.11-.61-.11-.97-.11-2.242 0-3.848 1.107-4.955 2.713v-2.52h-3.654v13.815h3.654zm11.877 0V13.65c0-2.907 1.218-4.236 3.156-4.236 1.523 0 2.713 1.135 2.713 2.99v7.89h3.655V13.65c0-2.907 1.273-4.236 3.211-4.236 1.523 0 2.714 1.135 2.714 2.99v7.89h3.626v-8.637c0-3.378-2.491-5.593-5.37-5.593-2.215 0-3.904.858-5.04 2.27-.996-1.467-2.685-2.27-4.54-2.27-1.8 0-3.156.582-4.125 1.55V6.479h-3.654v13.815h3.654z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
}

export function CheckmarkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-4-6h24v24H-4z" />
        <path
          stroke="#FFF"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m2 7 4.397 4 8.684-9"
        />
      </g>
    </svg>
  );
}

export interface PhoneCodeOptionCheckmarkProps {
  className?: string;
}

export function PhoneCodeOptionCheckmark({
  className = '',
}: PhoneCodeOptionCheckmarkProps) {
  return (
    <svg
      height="13"
      width="17"
      viewBox="0 0 17 13"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-4-6h24v24H-4z" />
        <path
          d="m2 7 4.397 4 8.684-9"
          stroke="#1A9ED9"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}

export interface SearchIconProps {
  className?: string;
}

export function SearchIcon({ className = '' }: SearchIconProps) {
  return (
    <svg
      height="16"
      width="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="m15.725 14.486-2.87-2.85c2.24-2.87 2.005-6.958-.57-9.533A7.15 7.15 0 0 0 7.195 0a7.15 7.15 0 0 0-5.092 2.103A7.15 7.15 0 0 0 0 7.194a7.15 7.15 0 0 0 2.103 5.091 7.15 7.15 0 0 0 5.091 2.103 7.11 7.11 0 0 0 4.423-1.533l2.85 2.89a.86.86 0 0 0 .629.255.947.947 0 0 0 .629-.256.878.878 0 0 0 0-1.258zm-3.106-7.292c0 1.455-.57 2.81-1.592 3.833a5.416 5.416 0 0 1-3.833 1.592c-1.435 0-2.81-.57-3.833-1.592A5.416 5.416 0 0 1 1.77 7.194c0-1.454.57-2.81 1.592-3.833A5.416 5.416 0 0 1 7.194 1.77c1.455 0 2.811.57 3.833 1.592a5.382 5.382 0 0 1 1.592 3.833z"
        fill="#B6C1CB"
        fillRule="nonzero"
      />
    </svg>
  );
}

export interface DropdownIconProps {
  className?: string;
}

export function DropdownIcon({ className }: DropdownIconProps) {
  return (
    <svg
      height="20"
      width="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z" />
        <path
          d="m6 8 4 4 4-4"
          stroke="#34393E"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export function DatePickerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x=".5"
        width="42"
        height="42"
        rx="21"
        fill="#fff"
        fillOpacity=".3"
      />
      <path
        d="M26.25 12.56V11c0-.41-.34-.75-.75-.75s-.75.34-.75.75v1.5h-6.5V11c0-.41-.34-.75-.75-.75s-.75.34-.75.75v1.56c-2.7.25-4.01 1.86-4.21 4.25-.02.29.22.53.5.53h16.92c.29 0 .53-.25.5-.53-.2-2.39-1.51-4-4.21-4.25zM29.5 18.84h-16c-.55 0-1 .45-1 1V26c0 3 1.5 5 5 5h8c3.5 0 5-2 5-5v-6.16c0-.55-.45-1-1-1zm-10.79 8.37c-.1.09-.21.16-.33.21-.12.05-.25.08-.38.08s-.26-.03-.38-.08-.23-.12-.33-.21c-.18-.19-.29-.45-.29-.71 0-.26.11-.52.29-.71.1-.09.21-.16.33-.21a1 1 0 0 1 .76 0c.12.05.23.12.33.21.18.19.29.45.29.71 0 .26-.11.52-.29.71zm.21-3.83c-.05.12-.12.23-.21.33-.1.09-.21.16-.33.21-.12.05-.25.08-.38.08s-.26-.03-.38-.08-.23-.12-.33-.21c-.09-.1-.16-.21-.21-.33A.995.995 0 0 1 17 23c0-.13.03-.26.08-.38s.12-.23.21-.33c.1-.09.21-.16.33-.21a1 1 0 0 1 .76 0c.12.05.23.12.33.21.09.1.16.21.21.33.05.12.08.25.08.38s-.03.26-.08.38zm3.29.33c-.1.09-.21.16-.33.21-.12.05-.25.08-.38.08s-.26-.03-.38-.08-.23-.12-.33-.21c-.18-.19-.29-.45-.29-.71 0-.26.11-.52.29-.71.1-.09.21-.16.33-.21.24-.11.52-.11.76 0 .12.05.23.12.33.21.18.19.29.45.29.71 0 .26-.11.52-.29.71z"
        fill="#000"
        fillOpacity=".7"
      />
    </svg>
  );
}

export function ArrowDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="m15.707 15.707-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L11 15.586V6a1 1 0 0 1 2 0v9.586l1.293-1.293a1 1 0 0 1 1.414 1.414z"
        fill="#1c1b1e"
        data-name="Down"
      />
    </svg>
  );
}
