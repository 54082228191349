import { UTMParameters } from './services/api/types/clients.responses.d';

export function hexToRgb(hex: string): number[] | null {
  // Remove the "#" from the beginning of the string
  hex = hex.replace(/^#/, '');

  // Check if the hex string is valid
  if (hex.length !== 3 && hex.length !== 6) {
    return null;
  }

  // Convert 3-digit hex values to 6-digit by doubling each digit
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Convert hex values to RGB values
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Return the RGB values as an array
  return [r, g, b];
}

export function removeCharsFromString(str: string, chars: string[]) {
  let newStr = '';
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    if (!chars.includes(char)) {
      newStr += char;
    }
  }
  return newStr;
}

export function checkForTapformDemoSubmissionParam() {
  // Check if the form is in demo submission mode
  if (!window?.location?.search) {
    return false;
  }
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('tapform-demo-submission-mode') === 'true';
}

export function collectUTMParams(): { parameter: string; value: string }[] {
  const windowSearch = window?.location?.search;
  if (windowSearch?.length) {
    const searchParams = new URLSearchParams(windowSearch);
    const utmParamsData: { parameter: string; value: string }[] = [];
    const UTMKeys: UTMParameters[] = Object.values(UTMParameters);
    UTMKeys.forEach((key) => {
      if (searchParams.has(key)) {
        utmParamsData.push({
          parameter: key,
          value: searchParams.get(key) as string,
        });
      }
    });
    return utmParamsData;
  }
  return [];
}

export function generateCssColorVariables(
  color: string | undefined | null,
  name: string,
) {
  if (!color) {
    return '';
  }
  const rgbBrandColor = hexToRgb(color);
  return `--${name}: #${color}; --${name}-r: ${rgbBrandColor?.[0]}; --${name}-g: ${rgbBrandColor?.[1]}; --${name}-b: ${rgbBrandColor?.[2]};`;
}
