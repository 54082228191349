export enum StatsType {
  OPEN_FROM_POPUP = 'open-from-popup',
  OPEN_FROM_WIDGET = 'open-from-widget',
  OPEN_FROM_TAKEOVER = 'open-from-takeover',
  OPEN_FROM_SDK = 'open-from-sdk',
  OPEN_FROM_LINK = 'open-from-link',
  SUBMIT_DROPOUT = 'form-dropout',
  START_PHONE_CALL_HEADER = 'start-phone-call-header',
  START_PHONE_CALL_SUBMIT = 'start-phone-call-submit',
  FORM_SUBMITTED = 'form-submitted',
  ONBOARDING_SIGNUP_OAUTH = 'onboarding-signup-oauth',
  ONBOARDING_SIGNUP_STARTED = 'onboarding-signup-started',
  ONBOARDING_SIGNUP_VERIFIED = 'onboarding-signup-verified',
  ONBOARDING_SIGNUP_STEP_ONE_FINISHED = 'onboarding-signup-step-one-finished',
  ONBOARDING_SIGNUP_STEP_TWO_FINISHED = 'onboarding-signup-step-two-finished',
  ONBOARDING_SIGNUP_STEP_THREE_FINISHED = 'onboarding-signup-step-three-finished',
  ONBOARDING_SIGNUP_STEP_FOUR_FINISHED = 'onboarding-signup-step-four-finished',
  ONBOARDING_SIGNUP_FINISHED = 'onboarding-signup-finished',
  ONBOARDING_LOGIN = 'onboarding-login',
  ONBOARDING_ROOFING_TEMPLATE_SELECTED = 'onboarding-roofing-template-selected',
  ONBOARDING_CLEANING_TEMPLATE_SELECTED = 'onboarding-cleaning-template-selected',
  ONBOARDING_GARAGE_DOOR_TEMPLATE_SELECTED = 'onboarding-garage-door-template-selected',
  ONBOARDING_HVAC_TEMPLATE_SELECTED = 'onboarding-hvac-template-selected',
  ONBOARDING_LANDSCAPING_TEMPLATE_SELECTED = 'onboarding-landscaping-template-selected',
  ONBOARDING_SOLAR_TEMPLATE_SELECTED = 'onboarding-solar-template-selected',
  ONBOARDING_LAWNCARE_TEMPLATE_SELECTED = 'onboarding-lawncare-template-selected',
  ONBOARDING_FLOOR_CLEANING_TEMPLATE_SELECTED = 'onboarding-floor-cleaning-template-selected',
  ONBOARDING_PAINTING_TEMPLATE_SELECTED = 'onboarding-painting-template-selected',
  ONBOARDING_PLUMBING_TEMPLATE_SELECTED = 'onboarding-plumbing-template-selected',
  ONBOARDING_JUNK_REMOVAL_TEMPLATE_SELECTED = 'onboarding-junk-removal-template-selected',
  ONBOARDING_LOCKSMITH_TEMPLATE_SELECTED = 'onboarding-locksmith-template-selected',
  ONBOARDING_CONCRETE_TEMPLATE_SELECTED = 'onboarding-concrete-template-selected',
  ONBOARDING_PEST_CONTROL_TEMPLATE_SELECTED = 'onboarding-pest-control-template-selected',
  ONBOARDING_POOL_SERVICES_TEMPLATE_SELECTED = 'onboarding-pool-services-template-selected',
  FORM_INTEGRATED_ON_WEBSITE = 'form-integrated-on-website',
  EMBEDDED_FORM_FIRST_INTERACTION = 'embedded-form-first-interaction',
}

export interface StatsReportRequest {
  type: StatsType;
  clientKey: string;
  leadId?: number;
  stepId?: number;
}

export interface StatsReportResponse {
  data: {
    success: boolean;
  };
}
