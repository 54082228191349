import React, { useEffect } from 'react';
import { TapformLogo } from '../../assets/images/icons';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { getFormStyles, getPlatformSettings } from '../../store/selectors';

const ANIMATION_DURATION = 1000;

function LoadingFormOverlay() {
  const style = useSelector(getFormStyles);
  const platformSettings = useSelector(getPlatformSettings);
  const logoRef = React.useRef<SVGSVGElement>(
    null,
  ) as React.MutableRefObject<SVGSVGElement>;
  const containerRef = React.useRef<HTMLDivElement>(
    null,
  ) as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    setTimeout(() => {
      logoRef.current?.classList.add(styles.logoEntry);
      setTimeout(() => {
        logoRef.current?.classList.add(styles.logoExit);
        setTimeout(() => {
          containerRef.current?.classList.add(styles.containerExit);
          setTimeout(() => {
            if (containerRef.current) {
              containerRef.current.style.display = 'none';
            }
          }, 0.25 * ANIMATION_DURATION);
        }, 0.125 * ANIMATION_DURATION);
      }, 0.6666 * ANIMATION_DURATION);
    }, 400);
    return () => {
      logoRef.current?.classList.remove(styles.logoExit);
      logoRef.current?.classList.remove(styles.logoEntry);
      containerRef.current?.classList.remove(styles.containerExit);
      if (containerRef.current?.style) {
        if (containerRef.current.style?.removeProperty) {
          containerRef.current.style.removeProperty('display');
        } else {
          containerRef.current.style.display = '';
        }
      }
    };
  }, []);

  if (style?.disableBranding) {
    return null;
  }

  if (!platformSettings?.loadingWatermark) {
    return null;
  }

  return (
    <div className={styles.container} ref={containerRef}>
      <TapformLogo innerRef={logoRef} className={styles.logo} />
    </div>
  );
}

export default LoadingFormOverlay;
