import React from 'react';
import Watermark from '../Watermark';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { getFormStyles } from '../../store/selectors';

function AppWatermark() {
  const style = useSelector(getFormStyles);
  if (style?.disableBranding) {
    return null;
  }
  return (
    <div className={styles.container}>
      <Watermark />
    </div>
  );
}

export default AppWatermark;
