import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ParentAppService from '../../services/parentApp';
import { actionFormPreviousStep } from '../../store/actions/form.actions';
import { actionUIClose } from '../../store/actions/ui.actions';
import {
  getIsFormOnFirstStep,
  getResponseStateSelector,
  getUIStateSelector,
} from '../../store/selectors';
import BackButton from '../BackButton';
import HeaderPhoneCta from '../HeaderPhoneCta';
import LogoImage from '../LogoImage';
import './index.scss';

function Header() {
  const isOnFirstStep = useSelector(getIsFormOnFirstStep);
  const { isFromLink, isFixed } = useSelector(getUIStateSelector);
  const dispatch = useDispatch();
  const response = useSelector(getResponseStateSelector('submit-response'));
  const headerRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  const backButtonCallback = React.useCallback(() => {
    if (process.env.REACT_APP_USE_NATIVE_BACK !== 'true') {
      if (isOnFirstStep || response?.status === 201) {
        ParentAppService.sendCloseMessage({});
        dispatch(actionUIClose());
      } else {
        dispatch(actionFormPreviousStep());
      }
    } else {
      history.back();
    }
  }, [isOnFirstStep, response]);

  React.useEffect(() => {
    const onScrollCallback = () => {
      const scrollPosition = window.scrollY;
      const percentScrolled = scrollPosition > 45 ? 1 : scrollPosition / 45;
      const percentShadow = percentScrolled * 16;
      headerRef.current.style.boxShadow = `0 8px 22px 0 rgba(0,0,0,${
        percentShadow / 100
      })`;
    };
    window.addEventListener('scroll', onScrollCallback);
    return () => {
      window.removeEventListener('scroll', onScrollCallback);
    };
  }, []);

  const visibleBack =
    ((isFixed || isFromLink) && !isOnFirstStep && !response) ||
    (!isFromLink && !isFixed);

  return (
    <div className="header-component-container" ref={headerRef}>
      {visibleBack ? <BackButton onClick={backButtonCallback} /> : <span />}
      <LogoImage className="header-component-logo" />
      <HeaderPhoneCta />
    </div>
  );
}

export default Header;
