import React from 'react';
import { BackIcon } from '../../assets/images/icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { actionUpdateAnimatingComponents } from '../../store/actions/ui.actions';
import { getAnimatingComponents } from '../../store/selectors';

export interface BackButtonProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  // onClick: Function;
  onClick: () => void;
}

function BackButton({ onClick }: BackButtonProps) {
  const dispatch = useDispatch();
  // const debouncedClick = React.useCallback(debounce(onClick, 250), [onClick]);
  const componentsAnimating = useSelector(getAnimatingComponents);
  const onClickCallback = () => {
    if (!componentsAnimating.length) {
      dispatch(
        actionUpdateAnimatingComponents({
          components: ['title'],
        }),
      );
      if (onClick) {
        onClick();
      }
    }
  };
  return (
    <button
      className="back-button-component"
      // onClick={debouncedClick as unknown as () => void}
      onClick={onClickCallback}
    >
      <BackIcon />
    </button>
  );
}

export default BackButton;
