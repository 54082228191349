import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormsLoadClientStepOption,
  InputType,
} from '../../services/api/types/forms.responses.d';
import { actionFormRecordAnswer } from '../../store/actions/form.actions';
import {
  getAnimatingComponents,
  getFormStateSelector,
} from '../../store/selectors';
import './index.scss';
import { AnimatePresence } from 'framer-motion';
import { actionRemoveAnimatingComponents } from '../../store/actions/ui.actions';
import Input from '../Input';
import SelectSmall from '../SelectSmall';
import SelectLarge from '../SelectLarge';
import { DesignType, ViewType } from '../../services/api/types/input';
import DatePicker from '../DatePicker';
import DateRangePicker from '../DateRangePicker';
import Textarea from '../Textarea';

export interface StepInputProps {
  inputType: InputType;
  inputDesignType: DesignType;
  inputViewType: ViewType;
  inputId: number;
  stepId: number;
  options: FormsLoadClientStepOption[] | null;
  className?: string;
  onSelect?: (optionId: number) => void;
  motionDelay?: number;
  isFirstTime: boolean;
  inputQuestion?: string;
  inputLabel?: string | null;
  inputLabelSecond?: string | null;
  onProceed?: () => void;
  optionalStep?: boolean;
}

function StepInput({
  inputType,
  options,
  inputId,
  stepId,
  className = '',
  onSelect,
  motionDelay = 0,
  isFirstTime = true,
  inputQuestion,
  inputLabel,
  inputLabelSecond,
  onProceed,
  inputDesignType,
  inputViewType,
  optionalStep,
}: StepInputProps) {
  const { stepAnswers } = useSelector(getFormStateSelector);
  const dispatch = useDispatch();
  const componentsAnimating = useSelector(getAnimatingComponents);

  const activeOptionIds = React.useMemo(() => {
    if (stepAnswers?.[stepId]?.answers[inputId]) {
      return stepAnswers[stepId].answers[inputId].optionId;
    }
  }, [stepAnswers, stepId, inputId]);

  const activeValue = React.useMemo(() => {
    if (stepAnswers?.[stepId]?.answers[inputId]) {
      return stepAnswers[stepId].answers[inputId].value;
    }
  }, [stepAnswers, stepId, inputId]);

  const initialDelay = React.useRef(isFirstTime ? motionDelay : 0.5);

  React.useEffect(() => {
    initialDelay.current = motionDelay;
  }, [motionDelay]);

  React.useEffect(() => {
    return () => {
      dispatch(actionRemoveAnimatingComponents({ components: ['options'] }));
    };
  }, []);

  const isSelector =
    inputType === InputType.SINGLE_SELECT ||
    inputType === InputType.MULTIPLE_SELECT;

  return (
    <div
      className={`step-input-component-container ${className} ${
        (options?.length || 0) * 220 > 960 ||
        inputDesignType === DesignType.BULLETS
          ? 'bottom-padding'
          : ''
      } ${inputType}`}
    >
      <div
        className={`step-input-component-holder ${inputType} ${inputViewType} ${inputDesignType}`}
      >
        <AnimatePresence
          mode="wait"
          onExitComplete={() => {
            dispatch(
              actionRemoveAnimatingComponents({ components: ['options'] }),
            );
          }}
        >
          {isSelector && options && inputDesignType === DesignType.BULLETS ? (
            <SelectSmall
              viewType={inputViewType}
              key={stepId}
              inputId={inputId}
              options={options}
              initialDelay={initialDelay.current}
              activeOptionIds={activeOptionIds}
              multiple={inputType === InputType.MULTIPLE_SELECT}
              onSelect={(optionSelected) => {
                if (!componentsAnimating.length) {
                  if (inputType === InputType.SINGLE_SELECT) {
                    if (
                      activeOptionIds?.includes(optionSelected.id) &&
                      optionalStep
                    ) {
                      dispatch(
                        actionFormRecordAnswer({
                          stepId,
                          inputId,
                          inputAnswer: {
                            optionId: [],
                            value: null,
                          },
                        }),
                      );
                    } else {
                      dispatch(
                        actionFormRecordAnswer({
                          stepId,
                          inputId,
                          inputAnswer: {
                            optionId: [optionSelected.id],
                            value: null,
                          },
                        }),
                      );
                    }
                  } else {
                    if (activeOptionIds?.includes(optionSelected.id)) {
                      // Unselect multiple
                      dispatch(
                        actionFormRecordAnswer({
                          stepId,
                          inputId,
                          inputAnswer: {
                            optionId:
                              activeOptionIds?.filter(
                                (aoi) => aoi !== optionSelected.id,
                              ) || [],
                            value: null,
                          },
                        }),
                      );
                    } else {
                      // Select multiple
                      dispatch(
                        actionFormRecordAnswer({
                          stepId,
                          inputId,
                          inputAnswer: {
                            optionId: activeOptionIds?.length
                              ? [...activeOptionIds, optionSelected.id]
                              : [optionSelected.id],
                            value: null,
                          },
                        }),
                      );
                    }
                  }
                  if (onSelect) {
                    onSelect(optionSelected.id);
                  }
                }
              }}
            />
          ) : isSelector && options && inputDesignType === DesignType.CARDS ? (
            <SelectLarge
              key={stepId}
              stepId={stepId}
              inputId={inputId}
              options={options}
              initialDelay={initialDelay.current}
              activeOptionIds={activeOptionIds}
              multiple={inputType === InputType.MULTIPLE_SELECT}
              onSelect={(optionSelected) => {
                if (!componentsAnimating.length) {
                  if (inputType === InputType.SINGLE_SELECT) {
                    dispatch(
                      actionFormRecordAnswer({
                        stepId,
                        inputId,
                        inputAnswer: {
                          optionId: [optionSelected.id],
                          value: null,
                        },
                      }),
                    );
                  } else {
                    if (activeOptionIds?.includes(optionSelected.id)) {
                      // Unselect multiple
                      dispatch(
                        actionFormRecordAnswer({
                          stepId,
                          inputId,
                          inputAnswer: {
                            optionId:
                              activeOptionIds?.filter(
                                (aoi) => aoi !== optionSelected.id,
                              ) || [],
                            value: null,
                          },
                        }),
                      );
                    } else {
                      // Select multiple
                      dispatch(
                        actionFormRecordAnswer({
                          stepId,
                          inputId,
                          inputAnswer: {
                            optionId: activeOptionIds?.length
                              ? [...activeOptionIds, optionSelected.id]
                              : [optionSelected.id],
                            value: null,
                          },
                        }),
                      );
                    }
                  }
                  if (onSelect) {
                    onSelect(optionSelected.id);
                  }
                }
              }}
            />
          ) : inputType === InputType.DATE_INPUT ? (
            <DatePicker
              key={`${stepId}`}
              variant="large"
              className="step-input-component-text-input"
              label={inputLabel || inputQuestion}
              tooltip={inputQuestion}
              defaultValue={(activeValue as any) || ''}
              valid={!!activeValue}
              motionDelay={initialDelay.current + 0.5}
              motionDuration={0.5}
              defaultFocused={true}
              onKeyUp={(e) => {
                if (e?.key === 'Enter' && onProceed) {
                  onProceed();
                }
              }}
              onChange={(value) => {
                dispatch(
                  actionFormRecordAnswer({
                    stepId,
                    inputId,
                    inputAnswer: {
                      optionId: null,
                      value: value || null,
                    },
                  }),
                );
              }}
            />
          ) : inputType === InputType.DATE_RANGE_INPUT ? (
            <DateRangePicker
              key={`${stepId}`}
              variant="large"
              className="step-input-component-text-input"
              labelFrom={inputLabel || inputQuestion}
              labelTo={inputLabelSecond || inputQuestion}
              defaultValue={
                (activeValue as any)?.length
                  ? {
                      from: (activeValue as any)?.split('_')[0],
                      to: (activeValue as any)?.split('_')[1],
                    }
                  : undefined
              }
              motionDelay={initialDelay.current + 0.5}
              motionDuration={0.5}
              defaultFocused={true}
              onKeyUp={(e) => {
                if (e?.key === 'Enter' && onProceed) {
                  onProceed();
                }
              }}
              onChange={(value) => {
                dispatch(
                  actionFormRecordAnswer({
                    stepId,
                    inputId,
                    inputAnswer: {
                      optionId: null,
                      value:
                        value.from && value.to
                          ? `${value.from}_${value.to}`
                          : null,
                    },
                  }),
                );
              }}
            />
          ) : inputType === InputType.TEXTAREA_INPUT ? (
            <Textarea
              key={`${stepId}`}
              variant="large"
              className="step-input-component-text-input"
              label={inputLabel || inputQuestion}
              tooltip={inputQuestion}
              defaultValue={(activeValue as any) || ''}
              motionDelay={initialDelay.current + 0.5}
              motionDuration={0.5}
              defaultFocused={true}
              onChange={(value) => {
                dispatch(
                  actionFormRecordAnswer({
                    stepId,
                    inputId,
                    inputAnswer: {
                      optionId: null,
                      value: value || null,
                    },
                  }),
                );
              }}
              maxLength={5000}
            />
          ) : (
            <Input
              key={`${stepId}`}
              variant="large"
              className="step-input-component-text-input"
              label={inputLabel || inputQuestion}
              tooltip={inputQuestion}
              defaultValue={(activeValue as any) || ''}
              motionDelay={initialDelay.current + 0.5}
              motionDuration={0.5}
              defaultFocused={true}
              onKeyUp={(e) => {
                if (e?.key === 'Enter' && onProceed) {
                  onProceed();
                }
              }}
              onChange={(value) => {
                dispatch(
                  actionFormRecordAnswer({
                    stepId,
                    inputId,
                    inputAnswer: {
                      optionId: null,
                      value: value || null,
                    },
                  }),
                );
              }}
              type={
                inputType === InputType.NUMBER_INPUT
                  ? 'number'
                  : inputType === InputType.EMAIL_INPUT
                  ? 'email'
                  : 'text'
              }
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default StepInput;
