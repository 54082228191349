import React from 'react';
import { useSelector } from 'react-redux';
import { PhoneIcon } from '../../assets/images/icons';
import APIService from '../../services/api/index.api';
import { FormsSubmitClientResponse } from '../../services/api/types/forms.responses';
import { StatsType } from '../../services/api/types/stats.response.d';
import {
  getFormDemoSubmission,
  getFormStateSelector,
  getFormTranslations,
  getResponseStateSelector,
} from '../../store/selectors';
import './index.scss';

function HeaderPhoneCta() {
  const { phoneNumber, currentStep, clientKey } =
    useSelector(getFormStateSelector);
  const response = useSelector(getResponseStateSelector('submit-response'));
  const translations = useSelector(getFormTranslations);
  const isDemoSubmission = useSelector(getFormDemoSubmission);

  if (!phoneNumber) {
    return <div className="empty" />;
  }

  const phoneCtaCallback = React.useCallback(() => {
    if (clientKey) {
      const stepId = typeof currentStep === 'number' && currentStep;
      const responseData =
        response && (response.response as FormsSubmitClientResponse);
      if (!isDemoSubmission) {
        APIService.statsService.report({
          type: StatsType.START_PHONE_CALL_HEADER,
          ...(stepId ? { stepId } : {}),
          ...(responseData ? { leadId: responseData.data.leadId } : {}),
          clientKey,
        });
      }
    }
  }, [response]);

  return (
    <a
      className="header-phone-cta-component-container"
      href={`tel:${phoneNumber}`}
      target="_parent"
      onClick={phoneCtaCallback}
    >
      <div className="header-phone-cta-component-left">
        <span className="header-phone-cta-component-left-label">
          {translations?.['phone-header-cta-label-1'] || ''}
        </span>
        <span className="header-phone-cta-component-left-phone">
          <PhoneIcon className="header-phone-cta-component-left-phone-icon" />
          {phoneNumber}
        </span>
      </div>
      <div className="header-phone-cta-component-action">
        <PhoneIcon />
      </div>
    </a>
  );
}

export default HeaderPhoneCta;
