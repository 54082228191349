/* eslint-disable symbol-description */
import React, { KeyboardEventHandler, useEffect } from 'react';
import { motion } from 'framer-motion';
import ParentAppService from '../../services/parentApp';
import styles from './index.module.scss';
import DatePicker, { DatePickerError } from '../DatePicker';
import { ArrowDownIcon } from '../../assets/images/icons';

export interface DateRangePickerValue {
  from?: string;
  to?: string;
}

export interface DateRangePickerProps {
  defaultValue?: DateRangePickerValue;
  placeholderFrom?: string;
  placeholderTo?: string;
  className?: string;
  name?: string;
  labelFrom?: string;
  labelTo?: string;
  errorsFrom?: DatePickerError;
  errorsTo?: DatePickerError;
  valid?: boolean;
  onChange?: (value: DateRangePickerValue) => void;
  style?: React.CSSProperties;
  motionDelay?: number;
  motionDuration?: number;
  autoComplete?: string;
  variant?: 'default' | 'large';
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  defaultFocused?: boolean;
  tooltipFrom?: string;
  tooltipTo?: string;
  format?: string;
}

function DateRangePicker({
  defaultValue,
  placeholderFrom,
  placeholderTo,
  labelFrom,
  labelTo,
  className = '',
  name = ParentAppService.generateUUID(),
  errorsFrom = [],
  errorsTo = [],
  valid = false,
  onChange,
  style,
  motionDelay = 0,
  motionDuration = 0.45,
  variant = 'default',
  onKeyUp,
  defaultFocused,
  tooltipFrom = '',
  tooltipTo = '',
  format,
}: DateRangePickerProps) {
  const [value, setValue] = React.useState<DateRangePickerValue>({
    from: defaultValue?.from || '',
    to: defaultValue?.to || '',
  });
  const [focusTrigger, setFocusTrigger] = React.useState<symbol | null>(null);
  const firstTime = React.useRef(true);

  function handleOnFromChange(newValue: string | null) {
    if (!newValue?.length) {
      setValue({ from: '', to: value.to });
      return;
    }
    setValue({ from: newValue, to: value.to });
    setFocusTrigger(Symbol());
  }

  function handleOnToChange(newValue: string | null) {
    if (!newValue?.length) {
      setValue({ from: value.from, to: '' });
      return;
    }
    setValue({ from: value.from, to: newValue });
  }

  useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false;
      return;
    }
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <motion.div
      className={`${styles.container} ${className}`}
      style={style}
      initial={{ opacity: 0, translateY: '30px' }}
      animate={{ opacity: 1, translateY: '0%' }}
      exit={{ opacity: 0, transition: { delay: 0 } }}
      transition={{
        duration: motionDuration,
        delay: motionDelay,
        ease: 'anticipate',
      }}
    >
      <div className={`${styles.holder}`}>
        <DatePicker
          name={name ? `${name}-start` : undefined}
          defaultValue={value.from}
          variant={variant}
          placeholder={placeholderFrom}
          onChange={handleOnFromChange}
          label={labelFrom}
          defaultFocused={defaultFocused}
          format={format}
          errors={errorsFrom}
          valid={valid}
          tooltip={tooltipFrom}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Tab') {
              setFocusTrigger(Symbol());
            }
          }}
        />
        <div className={styles.splitterHolder}>
          <div className={styles.splitter}>
            <div className={styles.splitterCircle}>
              <ArrowDownIcon className={styles.splitterIcon} />
            </div>
          </div>
        </div>
        <DatePicker
          name={name ? `${name}-end` : undefined}
          defaultValue={value.to}
          variant={variant}
          placeholder={placeholderTo}
          onChange={handleOnToChange}
          label={labelTo}
          format={format}
          errors={errorsTo}
          valid={valid}
          tooltip={tooltipTo}
          focusTrigger={focusTrigger}
          onKeyUp={(e) => {
            if (onKeyUp) onKeyUp(e);
          }}
        />
      </div>
    </motion.div>
  );
}

export default DateRangePicker;
